export default function Floor() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" y={400}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Floor">
          <rect width="1920" height="80" style={{ fill: '#dedfe7' }} />
          <ellipse cx="800" cy="40" rx="778.5" ry="30.5" style={{ fill: '#d2d0da' }} />
        </g>
      </g>
    </svg>
  );
}
