import React from "react";
import styled from "styled-components";
import PropertiesCoilType from "./PropertiesCoilType";

import PropertiesCompareType from "./PropertiesCompareType";
import PropertiesContactType from "./PropertiesContactType";
import PropertiesCounterType from "./PropertiesCounterType";
import PropertiesMathType from "./PropertiesMathType";
import PropertiesParameters from "./PropertiesParameters";
import PropertiesTimerType from "./PropertiesTimerType";

const Container = styled.div``;

const Properties: React.FC = () => {
  return (
    <Container>
      <h2>Element Properties:</h2>
      <PropertiesParameters />
      <PropertiesCoilType />
      <PropertiesCompareType />
      <PropertiesContactType />
      <PropertiesCounterType />
      <PropertiesMathType />
      <PropertiesTimerType />
    </Container>
  );
};

export default Properties;
