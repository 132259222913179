import { useSelector } from 'react-redux';
import { Store } from '../interface';

export default function LightStack() {
  const lightstackAmber = useSelector(
    (state: Store) => state.variables['c73859d3-971c-4be0-8d68-3539213cf313'],
  );
  const lightstackGreen = useSelector(
    (state: Store) => state.variables['93d762a0-dfed-4e08-aded-f573a741e316'],
  );
  const lightstackRed = useSelector(
    (state: Store) => state.variables['baf9907b-dfbf-4483-a5a3-976f0e6ab00d'],
  );
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const lightStackAmberColor = lightstackAmber.value && simulation ? '#ff9924' : '#9e6400';
  const lightStackGreenColor = lightstackGreen.value && simulation ? '#06fd1a' : '#347a3a';
  const lightStackRedColor = lightstackRed.value && simulation ? '#ff2c2c' : '#740202';

  return (
    <g id="lightStack">
      <path d="M 19 116 v 70 h 32" fill="transparent" stroke="#333" strokeWidth={8} />
      <rect x="6" y="113" width="26" height="5" style={{ fill: '#333' }} />
      <rect x="7" y="97" width="24" height="16" style={{ fill: lightStackGreenColor }} />
      <rect x="6" y="92" width="26" height="5" style={{ fill: '#333' }} />
      <rect x="7" y="76" width="24" height="16" style={{ fill: lightStackAmberColor }} />
      <rect x="6" y="71" width="26" height="5" style={{ fill: '#333' }} />
      <rect x="7" y="55" width="24" height="16" style={{ fill: lightStackRedColor }} />
      <rect x="6" y="50" width="26" height="5" />
    </g>
  );
}
