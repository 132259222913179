import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ASSIGN_PARAMETER } from '../../store/types';
import { filterByType } from '../../helpers/variables';
import { ElementParameter, Store, VariableOption } from '../../interface';

const Container = styled.div`
  background-color: white;
  margin: 0px;
`;
const H3 = styled.h3`
  text-transform: capitalize;
`;
const StyledSelect = styled(Select)`
  margin-bottom: 1em;
`;

const PropertiesParameters: React.FC = () => {
  const dispatch = useDispatch();
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const element = useSelector((state: Store) => state.elements[selectedUuid]);
  const variables = useSelector((state: Store) => state.variables, shallowEqual);

  const handleOnchange = (
    option: VariableOption,
    index: number,
    paramGrp: string,
    paramUuid: string,
  ) => {
    if (option.value === paramUuid) return;
    dispatch({
      type: ASSIGN_PARAMETER,
      payload: {
        variableUuid: option.value,
        index,
        type: paramGrp,
      },
    });
  };

  return (
    <Container>
      {Object.keys(element.parameters).map((paramGrp, index) => (
        <div key={`paramGrp${index}`}>
          <H3>{`${paramGrp} Parameters`}</H3>
          {element.parameters[paramGrp].map((parameter: ElementParameter, index: number) => {
            const options = filterByType(variables, parameter.type);
            return (
              <StyledSelect
                key={`${paramGrp}Param${index}`}
                isSearchable
                onChange={(option) =>
                  handleOnchange(option as VariableOption, index, paramGrp, parameter.uuid)
                }
                options={options}
                value={options.filter((option: VariableOption) => option.value === parameter.uuid)}
              />
            );
          })}
        </div>
      ))}
    </Container>
  );
};

export default PropertiesParameters;
