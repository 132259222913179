import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../interface';
import { REMOVE_BOX, UPDATE_BOX_POS } from '../store/types';

const move = keyframes`
  0% {
    transform: translateX(0px);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(1200px);
    opacity: 0;
  }
`;

const MovingBox = styled.g<{ conveyorOn: boolean }>`
  animation: ${move} 10s linear;
  animation-play-state: ${(p) => (p.conveyorOn ? 'running' : 'paused')};
`;

interface Props {
  index: number;
}

export default function Box({ index }: Props) {
  const conveyorOn = useSelector(
    (state: Store) => state.variables['9df050ca-8888-4381-b866-813a60b59b18'].value,
  );
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const ref = useRef<SVGSVGElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      const rect = ref.current?.getBoundingClientRect();
      if (rect && simulation && conveyorOn)
        dispatch({ type: UPDATE_BOX_POS, payload: { index, left: rect.left, right: rect.right } });
    }, 100);
    return () => clearInterval(interval);
  }, [conveyorOn, dispatch, index, simulation]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={220}
      y={172}
      ref={ref}
      onAnimationEnd={() => dispatch({ type: REMOVE_BOX, payload: index })}
    >
      <MovingBox id="Box" conveyorOn={conveyorOn && simulation ? true : false}>
        <rect style={{ fill: '#ffd000' }} width="93" height="70" />
        <rect style={{ fill: '#f0ab00' }} x="39" width="15" height="21" />
        <rect style={{ fill: '#fff', opacity: 0.8 }} x="64" y="24" width="24" height="22" />
        <g style={{ opacity: 0.8 }}>
          <g id="layer1">
            <g id="g2094">
              <g id="g2087">
                <rect
                  id="rect2037"
                  style={{ fill: '#fff' }}
                  x="7.15"
                  y="42.63"
                  width="11.34"
                  height="11.45"
                />
                <g id="g2098">
                  <path
                    id="path2059"
                    d="M9.43,45.16A4.75,4.75,0,0,0,10,47l.52-.83-.15-.05L10.12,46l.11-.19.38-.69Zm1.61,0-.38.68.16.06L11,46l-.12.2-.76,1.19a3.17,3.17,0,0,0,2.61,1.55c1.76,0,3.19-1.65,3.34-3.76Z"
                  />
                  <rect id="rect2072" x="12.44" y="48.52" width="0.72" height="3.36" />
                  <rect id="rect2074" x="10.95" y="51.33" width="3.69" height="0.61" />
                </g>
              </g>
              <g id="g2072">
                <rect
                  id="rect2035"
                  style={{ fill: '#fff' }}
                  x="20.14"
                  y="29.48"
                  width="11.34"
                  height="11.45"
                />
                <g id="g2109">
                  <rect id="rect2099" x="22.12" y="38.5" width="7.43" height="0.77" />
                  <rect id="rect2101" x="23.39" y="32.83" width="1.21" height="5.28" />
                  <path id="path2103" d="M25.1,33.22H22.88l.55-.94.56-.94.56.94Z" />
                  <rect id="rect2105" x="27.08" y="32.83" width="1.21" height="5.28" />
                  <path id="path2107" d="M28.81,33.22H26.59l.55-.94.56-.94.56.94Z" />
                </g>
              </g>
              <g id="g2081">
                <rect
                  id="rect2039"
                  style={{ fill: '#fff' }}
                  x="20.14"
                  y="42.63"
                  width="11.34"
                  height="11.45"
                />
                <g id="g2144">
                  <path
                    id="path2116"
                    d="M26,45.32c-2.05,0-3.71,1.18-3.71,2.63,0-.25.42-.45.93-.45s.92.2.92.45c0-.25.42-.45.93-.45s.93.2.93.45c0-.25.42-.45.93-.45s.92.2.92.45c0-.25.42-.45.93-.45s.93.2.93.45C29.67,46.5,28,45.32,26,45.32Z"
                  />
                  <path
                    id="rect2131"
                    d="M25.65,44.56v6.75a.31.31,0,0,1-.61,0v-.15h-.55v.15a.88.88,0,1,0,1.76,0V44.56Z"
                  />
                </g>
              </g>
              <g id="g2062">
                <rect
                  id="rect1306"
                  style={{ fill: '#fff' }}
                  x="7.15"
                  y="29.48"
                  width="11.34"
                  height="11.45"
                />
                <g id="g2054">
                  <g id="g2177">
                    <path id="rect2148" d="M11.34,32.42l1.49-1.13,1.48,1.13-1.48,1.13Z" />
                    <path id="rect2170" d="M14.31,32.88l-1.27,1v1.51l1.27-1Z" />
                    <path id="path2175" d="M11.33,32.88l1.27,1v1.51l-1.27-1Z" />
                  </g>
                  <path
                    id="path1324"
                    style={{ fillRule: 'evenodd' }}
                    d="M12.44,38.53V37.38c0-.63-.85-1.54-1.4-2.19a.42.42,0,0,0-.56-.06.57.57,0,0,0,0,.59l.55.84c.09.16-.14.29-.27.12-.71-1-.9-1.17-.9-1.56V33.53a.44.44,0,0,0-.39-.46.43.43,0,0,0-.38.42v3.07c0,.4,1.26,1.6,1.26,2.15v.4h2.1Z"
                  />
                  <path
                    id="path2052"
                    style={{ fillRule: 'evenodd' }}
                    d="M13.21,38.53V37.38c0-.63.84-1.54,1.39-2.19a.42.42,0,0,1,.56-.06.55.55,0,0,1,0,.59l-.56.84c-.09.16.14.29.27.12.71-1,.9-1.17.9-1.56V33.56a.41.41,0,0,1,.36-.43.4.4,0,0,1,.36.39v3c0,.4-1.21,1.6-1.21,2.15v.4H13.21Z"
                  />
                </g>
              </g>
            </g>
          </g>
          <text
            id="svg_1"
            data-name="svg 1"
            style={{ isolation: 'isolate', fontSize: '1.71px', fontFamily: 'ArialMT, Arial' }}
            transform="translate(7.28 60.22)"
          >
            FRAGILE
          </text>
          <text
            id="svg_2"
            data-name="svg 2"
            style={{ isolation: 'isolate', fontSize: '1.71px', fontFamily: 'ArialMT, Arial' }}
            transform="translate(7.28 64.02)"
          >
            PLEASE, HANDLE WITH CARE
          </text>
          <rect id="svg_3" data-name="svg 3" x="7.12" y="64.78" width="24.71" height="0.72" />
          <rect id="svg_4" data-name="svg 4" x="7.01" y="61.26" width="24.71" height="0.72" />
        </g>
      </MovingBox>
    </svg>
  );
}
