import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Store } from "../../interface";
import { ELEMENT, OUT_ON, SELECTED } from "../../consts/colors";
import { OTE, OTL, OTU } from "../../consts/elementTypes";
import { DELETE_OBJECT, SET_SIMULATION } from "../../store/types";

import Branch from "./Branch";
import Element from "./Element";
import Wire from "./Wire";

const Container = styled.div`
  display: flex;
  outline: none;
`;
const ElementList = styled.div`
  display: flex;
  flex-basis: 100%;
`;
const PowerRail = styled.div<{ fill: string }>`
  background-color: ${(props) => props.fill};
  flex-shrink: 0;
  min-height: 5em;
  width: 0.5em;
`;
interface Props {
  uuid: string;
}

const Rung: React.FC<Props> = (props: Props) => {
  const { uuid } = props;
  const dispatch = useDispatch();
  const { elements, out } = useSelector((state: Store) => state.rungs[uuid]);
  const diagramElements = useSelector((state: Store) => state.elements);
  const diagramBranches = useSelector((state: Store) => state.branches);
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const selected = selectedUuid === uuid;
  const fillLeft = simulation ? OUT_ON : selected ? SELECTED : ELEMENT;
  const fillRight = simulation ? (out ? OUT_ON : ELEMENT) : selected ? SELECTED : ELEMENT;
  const rungElementTypes = elements.map((rungElement) => diagramElements[rungElement]?.type);
  const coils = [OTE, OTL, OTU];
  const lastCoilGrpIndex = rungElementTypes.map((type) => (coils.includes(type) ? "coil" : null)).lastIndexOf(null);
  const elementsPlusWire = [...elements];
  elementsPlusWire.splice(lastCoilGrpIndex + 1, 0, "WIRE");
  const elementBeforeWire = elements[lastCoilGrpIndex];
  const elementBeforeWireOut = Object.keys(diagramBranches).includes(elementBeforeWire)
    ? diagramBranches[elementBeforeWire].out
    : Object.keys(diagramElements).includes(elementBeforeWire)
    ? diagramElements[elementBeforeWire].out
    : true;
  const wireColor = simulation ? (elementBeforeWireOut ? OUT_ON : ELEMENT) : selected ? SELECTED : ELEMENT;

  const getComponentName = (elementUuid: string) => {
    if (Object.keys(diagramBranches).includes(elementUuid)) return Branch;
    if (Object.keys(diagramElements).includes(elementUuid)) return Element;
    return Wire;
  };

  const handleOnCLick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    return dispatch({
      type: "SELECT_OBJECT",
      payload: { uuid },
    });
  };
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Delete") return;
    dispatch({ type: DELETE_OBJECT });
    dispatch({ type: SET_SIMULATION, payload: { value: false } });
  };

  return (
    <Container onClick={(e) => handleOnCLick(e)} onKeyDown={(e) => handleOnKeyDown(e)} tabIndex={0}>
      <PowerRail fill={fillLeft} />
      <ElementList>
        {elementsPlusWire.map((uuid: string) => {
          const Component = getComponentName(uuid);
          return <Component key={`element${uuid}`} uuid={uuid} parrentSelected={selected} wireColor={wireColor} />;
        })}
      </ElementList>
      <PowerRail fill={fillRight} />
    </Container>
  );
};

export default Rung;
