import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ElementParameters, Store } from "../../interface";
import { ELEMENT, ELEMENT_NOT_CFG, OUT_ON } from "../../consts/colors";
import { OTL, OTU } from "../../consts/elementTypes";

const Svg = styled.svg<{ fill: string }>`
  fill: ${(props) => props.fill};
  stroke: ${(props) => props.fill};
`;

interface Props {
  fill: string;
  parameters: ElementParameters;
  type: string;
}

const Coil: React.FC<Props> = (props: Props) => {
  const { fill, parameters, type } = props;
  const outputID = parameters.output[0].uuid;
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const variable = useSelector((state: Store) => state.variables[outputID]);
  const coilFill = simulation ? (variable.value ? OUT_ON : ELEMENT) : fill;
  return (
    <Svg fill={coilFill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <title>Coil</title>
      <text
        x="8"
        y="12"
        stroke={variable ? ELEMENT : ELEMENT_NOT_CFG}
        style={{ font: "14px sans-serif" }}
        className="small"
      >
        {variable ? variable.name : "--Select--"}
      </text>
      <line y1="40" x2="20" y2="40" strokeWidth="8" />
      <line x1="60" y1="40" x2="80" y2="40" strokeWidth="8" />
      <circle cx="40" cy="40" r="20" fill="transparent" strokeWidth="8" />
      <text x="30" y="50" style={{ font: "28px sans-serif" }}>
        {type === OTL && "S"}
        {type === OTU && "R"}
      </text>
    </Svg>
  );
};

export default Coil;
