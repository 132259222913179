export const CYCLE_TIME = 66;

export const FB_PARAM_TYPES = {
  INPUT: 'Input',
  OUTPUT: 'Output',
};

export const APP = {
  changes: [],
  savedProjects: [],
  currentVersion: 0,
};
