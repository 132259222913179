import React from "react";
import { useSelector } from "react-redux";

import { ELEMENT, ELEMENT_NOT_CFG, PROGRESS } from "../../consts/colors";
import { ElementParameters, Store } from "../../interface";

interface Props {
  fill: string;
  parameters: ElementParameters;
}

const Timer: React.FC<Props> = (props: Props) => {
  const { parameters, fill } = props;
  const inOutID = parameters.inOut[0].uuid;
  const variable = useSelector((state: Store) => state.variables[inOutID]);
  const PT = variable?.subVars.PT;
  const ET = variable?.subVars.ET;
  const pt = useSelector((state: Store) => state.variables[PT]?.value);
  const et = useSelector((state: Store) => state.variables[ET]?.value);
  let timerPercent = 0;
  if (variable?.subVars) {
    if (typeof pt === "number" && typeof et === "number") {
      timerPercent = pt ? et / pt : 0;
    }
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className="symbol">
      <text
        x="8"
        y="12"
        stroke={variable ? ELEMENT : ELEMENT_NOT_CFG}
        style={{ font: "14px sans-serif" }}
        className="small"
      >
        {variable ? variable.name : "--Select--"}
      </text>
      <title>Timer</title>
      <circle
        r="12"
        cx="40"
        cy="40"
        fill="transparent"
        stroke={PROGRESS}
        strokeWidth="24"
        strokeDasharray={75.36}
        strokeDashoffset={75.36 - 75.36 * timerPercent}
        transform="rotate(-90, 40,40)"
      />
      <circle r="22" cx="40" cy="40" fill="transparent" stroke={fill} strokeWidth="6" />
      <line x1="40" x2="40" y1="40" y2="26" stroke={ELEMENT} strokeWidth="4" strokeLinecap="round" />
      <line x1="40" x2="48" y1="40" y2="46" stroke={ELEMENT} strokeWidth="4" strokeLinecap="round" />
      <line y1="40" x2="20" y2="40" stroke={fill} strokeWidth="8" />
      <line x1="60" y1="40" x2="80" y2="40" stroke={fill} strokeWidth="8" />
    </svg>
  );
};

export default Timer;
