import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Store } from "../../interface";
import { CTD, CTU } from "../../consts/elementTypes";

import PropertiesTypeOption from "./PropertiesTypeOption";

const Container = styled.div`
  background-color: white;
  width: 100%;
`;
const TypeList = styled.div`
  display: flex;
  width: 100%;
`;

const PropertiesCounterType: React.FC = () => {
  const element = useSelector((state: Store) => state.elements[state.temp.selectedUuid]);

  return [CTD, CTU].includes(element.type) ? (
    <Container>
      <h3>Type:</h3>
      <TypeList>
        <PropertiesTypeOption elementType={element.type} option={CTU} />
        <PropertiesTypeOption elementType={element.type} option={CTD} />
      </TypeList>
    </Container>
  ) : null;
};

export default PropertiesCounterType;
