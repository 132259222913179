import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ElementsAll, Store } from "../../interface";
import { ADD_BRANCH, ADD_ELEMENT, ADD_RUNG, OPEN_ALERT_SNACKBAR, SET_SIMULATION } from "../../store/types";
import { BG_TOOLBOX } from "../../consts/colors";
import { coil, compare, contact, counter, math, move, timer } from "./elements";

import { ReactComponent as Branch } from "../../svg/toolbox/branch.svg";
import { ReactComponent as Coil } from "../../svg/toolbox/coil.svg";
import { ReactComponent as Contact } from "../../svg/toolbox/contact.svg";
import { ReactComponent as Counter } from "../../svg/toolbox/counter.svg";
import { ReactComponent as Compare } from "../../svg/toolbox/compare.svg";
import { ReactComponent as Math } from "../../svg/toolbox/math.svg";
import { ReactComponent as Move } from "../../svg/toolbox/move.svg";
import { ReactComponent as Rung } from "../../svg/toolbox/rung.svg";
import { ReactComponent as Timer } from "../../svg/toolbox/timer.svg";

import ToolboxButton from "./ToolboxButton";

const ToolList = styled.div`
  background-color: ${BG_TOOLBOX};
  display: flex;
  flex: 1 1 auto;
  grid-area: toolbox;
`;

const Toolbox: React.FC = () => {
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const dispatch = useDispatch();
  const handleOnClick = (actionType: string, element?: ElementsAll) =>
    selectedUuid
      ? dispatch({ type: actionType, payload: { element } })
      : dispatch({
          type: OPEN_ALERT_SNACKBAR,
          payload: {
            color: "warning",
            open: true,
            text: "Select a rung that you want to add an element onto.",
          },
        });

  return (
    <ToolList
      onClick={() => (simulation ? dispatch({ type: SET_SIMULATION, payload: { value: false } }) : null)}
      data-intro="Add Ladder Logic elements, braches and new rungs"
      data-step="2"
    >
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, contact)} Svg={Contact} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, coil)} Svg={Coil} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, timer)} Svg={Timer} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, counter)} Svg={Counter} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, math)} Svg={Math} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, compare)} Svg={Compare} />
      <ToolboxButton onClick={() => handleOnClick(ADD_ELEMENT, move)} Svg={Move} />
      <ToolboxButton onClick={() => handleOnClick(ADD_BRANCH)} Svg={Branch} />
      <ToolboxButton onClick={() => dispatch({ type: ADD_RUNG })} Svg={Rung} />
    </ToolList>
  );
};

export default Toolbox;
