import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Store } from "../../interface";
import { ELEMENT, OUT_ON, SELECTED } from "../../consts/colors";
import * as ELEMENTS from "../../consts/elementTypes";
import { OPEN_ELEM_PROPERTIES, SELECT_OBJECT } from "../../store/types";

import Coil from "./ElementCoil";
import Compare from "./ElementCompare";
import Contact from "./ElementContact";
import Counter from "./ElementCounter";
import Math from "./ElementMath";
import Move from "./ElementMove";
import Timer from "./ElementTimer";

const Container = styled.div`
  flex-shrink: 0;
  height: 5em;
  width: 5em;
`;

interface Props {
  uuid: string;
  parrentSelected: boolean;
}

const Element: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { uuid, parrentSelected } = props;
  const element = useSelector((state: Store) => state.elements[uuid]);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const { out, type } = element;
  const selected = selectedUuid === uuid;
  const fill = simulation ? (out ? OUT_ON : ELEMENT) : selected || parrentSelected ? SELECTED : ELEMENT;

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    uuid === selectedUuid && !simulation
      ? dispatch({
          type: OPEN_ELEM_PROPERTIES,
          payload: true,
        })
      : dispatch({
          type: SELECT_OBJECT,
          payload: { uuid },
        });
  };

  return (
    <Container
      onClick={(e) => {
        handleOnClick(e);
      }}
    >
      {(() => {
        switch (type) {
          case ELEMENTS.OTE:
          case ELEMENTS.OTL:
          case ELEMENTS.OTU:
            return <Coil fill={fill} parameters={element.parameters} type={type} />;
          case ELEMENTS.XIO:
          case ELEMENTS.XIC:
          case ELEMENTS.OSP:
          case ELEMENTS.OSN:
            return <Contact fill={fill} parameters={element.parameters} type={type} />;
          case ELEMENTS.CTD:
          case ELEMENTS.CTU:
            return <Counter fill={fill} parameters={element.parameters} />;
          case ELEMENTS.TOF:
          case ELEMENTS.TON:
          case ELEMENTS.TONR:
            return <Timer fill={fill} parameters={element.parameters} />;
          case ELEMENTS.ADD:
          case ELEMENTS.SUB:
          case ELEMENTS.MUL:
          case ELEMENTS.DIV:
            return <Math configured={element.configured} fill={fill} type={type} />;
          case ELEMENTS.EQU:
          case ELEMENTS.NEQ:
          case ELEMENTS.GRT:
          case ELEMENTS.GEQ:
          case ELEMENTS.LES:
          case ELEMENTS.LEQ:
            return <Compare configured={element.configured} fill={fill} type={type} />;
          case ELEMENTS.MOV:
            return <Move configured={element.configured} fill={fill} />;
          default:
            console.log(`No case for ${type}`);
            return null;
        }
      })()}
    </Container>
  );
};

export default Element;
