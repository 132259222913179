import { Store } from "../interface";
import { addRung } from "./addRung";

export const deleteObject = (objectUuid: string, draft: Store): Store => {
  const { runglist, elements } = draft;
  return Object.keys(elements).includes(objectUuid)
    ? deleteElement(objectUuid, draft)
    : runglist.includes(objectUuid)
    ? deleteRung(objectUuid, draft)
    : deleteBranchRung(objectUuid, draft);
};

const deleteBranch = (branchUuid: string, draft: Store): Store => {
  for (const uuid of draft.branches[branchUuid].rungs) {
    deleteRung(uuid, draft);
  }
  Object.keys(draft.rungs).map(
    (rung) => (draft.rungs[rung].elements = draft.rungs[rung].elements.filter((element) => element !== branchUuid))
  );
  delete draft.branches[branchUuid];
  return draft;
};

const deleteBranchRung = (rungUuid: string, draft: Store): Store => {
  const { branches, rungs } = draft;
  const parrentBranch = Object.keys(branches).find((branch) => branches[branch].rungs.includes(rungUuid));
  if (parrentBranch) {
    const remainingRung = branches[parrentBranch].rungs.find((rung) => rung !== rungUuid);
    if (remainingRung) {
      const remainingElements = rungs[remainingRung].elements;
      const parrentRung = Object.keys(rungs).find((rung) => rungs[rung].elements.includes(parrentBranch));
      if (parrentRung) {
        const parentBranchIndex = rungs[parrentRung].elements.findIndex((element) => element === parrentBranch);
        draft.rungs[parrentRung].elements = rungs[parrentRung].elements.filter((element) => element !== parrentBranch);
        draft.rungs[parrentRung].elements.splice(parentBranchIndex, 0, ...remainingElements);
        delete draft.branches[parrentBranch];
        delete draft.rungs[remainingRung];
        deleteRung(rungUuid, draft);
      }
    }
  }
  return draft;
};

const deleteElement = (elementUuid: string, draft: Store): Store => {
  const rungs = draft.rungs;
  const variables = draft.variables;
  const parrentRung = Object.keys(rungs).find((rungUuid) =>
    rungs[rungUuid].elements.find((element) => element === elementUuid)
  );
  const elementParameters = draft.elements[elementUuid].parameters;
  Object.keys(elementParameters).map((group) =>
    elementParameters[group].map((variable) => {
      if (variable.uuid) {
        draft.variables[variable.uuid].usedBy = variables[variable.uuid]?.usedBy.filter(
          (element) => element !== elementUuid
        );
      }
    })
  );
  if (parrentRung) {
    draft.rungs[parrentRung].elements = rungs[parrentRung].elements.filter((element) => element !== elementUuid);
    delete draft.elements[elementUuid];
    draft.temp.selectedUuid = parrentRung;
  }
  return draft;
};

const deleteRung = (rungUuid: string, draft: Store): Store => {
  const { branches, runglist, rungs } = draft;
  for (const uuid of draft.rungs[rungUuid].elements) {
    Object.keys(branches).includes(uuid) ? deleteBranch(uuid, draft) : deleteElement(uuid, draft);
  }
  draft.runglist = runglist.filter((rung) => rung !== rungUuid);
  Object.keys(branches).map(
    (branch) => (branches[branch].rungs = branches[branch].rungs.filter((rung) => rung !== rungUuid))
  );
  delete rungs[rungUuid];
  !draft.runglist[0] && addRung(draft);
  draft.temp.selectedUuid = draft.runglist[0];
  return draft;
};
