import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ELEMENT, ELEMENT_NOT_CFG, PROGRESS } from "../../consts/colors";
import { ElementParameters, Store } from "../../interface";

const Svg = styled.svg`
  cursor: default;
`;

interface Props {
  fill: string;
  parameters: ElementParameters;
}

const Counter: React.FC<Props> = (props: Props) => {
  const { parameters, fill } = props;
  const inOutID = parameters.inOut[0].uuid;
  const variable = useSelector((state: Store) => state.variables[inOutID]);
  const PV = variable?.subVars.PV;
  const CV = variable?.subVars.CV;
  const pv = useSelector((state: Store) => state.variables[PV]?.value);
  const cv = useSelector((state: Store) => state.variables[CV]?.value);
  let progress = 0;
  if (variable?.subVars) {
    if (typeof pv === "number" && pv > 0 && typeof cv === "number") {
      progress = Math.min(Math.max((cv / pv) * 50, 0), 50);
    }
  }

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className="symbol">
      <text
        className="small"
        stroke={variable ? ELEMENT : ELEMENT_NOT_CFG}
        style={{ font: "14px sans-serif" }}
        x="8"
        y="12"
      >
        {variable ? variable.name : "--Select--"}
      </text>
      <title>Counter</title>
      <rect x="16" y="24" width={progress} height="32" fill={PROGRESS} strokeWidth="0" />
      <rect x="14" y="24" width="52" height="32" fill="transparent" stroke={fill} strokeWidth="4" />
      <text
        style={{ font: "18px sans-serif", fontWeight: "bold" }}
        x="40"
        y="42"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        0
      </text>
      <text
        style={{ font: "18px sans-serif", fontWeight: "bold" }}
        x="24"
        y="42"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        0
      </text>
      <text
        style={{ font: "18px sans-serif", fontWeight: "bold" }}
        x="56"
        y="34"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        6
      </text>
      <text
        style={{ font: "18px sans-serif", fontWeight: "bold" }}
        x="56"
        y="50"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        7
      </text>
      <line x1="32" x2="32" y1="22" y2="58" stroke={fill} strokeWidth="2" />
      <line x1="48" x2="48" y1="22" y2="58" stroke={fill} strokeWidth="2" />

      <line y1="40" x2="12" y2="40" stroke={fill} strokeWidth="8" />
      <line x1="68" y1="40" x2="80" y2="40" stroke={fill} strokeWidth="8" />
    </Svg>
  );
};

export default Counter;
