const typography = {
  h1: {
    fontSize: '4.375rem',
    lineHeight: 1.1,
    fontWeight: 800,
  },
  h2: {
    fontSize: '2.5rem',
    lineHeight: 1.1,
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.75rem',
    lineHeight: 1.1,
    fontWeight: 400,
  },
  h4: {
    fontSize: '1.5rem',
    lineHeight: 1.1,
    fontWeight: 300,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.1,
    fontWeight: 300,
  },
  body2: {
    fontSize: '1rem',
    lineHeight: 1.1,
    fontWeight: 300,
  },
};

export default typography;
