import React, { useRef, useState } from 'react';
import { Avatar, Box, Button, Checkbox, Container, Grid, Link } from '@mui/material';
import { TextField, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';

import { auth } from '../../helpers/firebase';
import { signStyles } from './styles';
import { createUserWithEmailAndPassword } from 'firebase/auth';

interface Props {
  handleFormChange: (form: string) => void;
}

const SignUp: React.FC<Props> = (props: Props) => {
  const { handleFormChange } = props;
  const classes = signStyles();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailRef.current && passwordRef.current) {
      createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
        .then()
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        <form onSubmit={(e) => handleSubmit(e)} className={classes.form} noValidate>
          {error && (
            <Grid item xs={12}>
              <Alert className={classes.alert} severity={'error'} color={'error'}>
                {error}
              </Alert>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            inputRef={emailRef}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          <Grid item xs={12}>
            <Box display="flex" mt={2}>
              <Checkbox
                className={classes.checkbox}
                checked={checked}
                onChange={() => setChecked(!checked)}
                color="primary"
                inputProps={{ 'aria-label': 'terms checkbox' }}
              />
              <Typography>
                I have read and agree to the{' '}
                <Link
                  href="https://codingplc.com/terms-of-service/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of service
                </Link>{' '}
                agreement and{' '}
                <Link href="https://codingplc.com/privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy policy
                </Link>
                .
              </Typography>
            </Box>
          </Grid>
          <Button
            disabled={!checked}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link onClick={() => handleFormChange('signIn')} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={3}></Box>
    </Container>
  );
};

export default SignUp;
