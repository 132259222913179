import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Delete } from "../../svg/binDelete.svg";
import { ReactComponent as ArrowDown } from "../../svg/arrowDownRound.svg";
import { ReactComponent as ArrowLeft } from "../../svg/arrowLeftRound.svg";
import { ReactComponent as ArrowRight } from "../../svg/arrowRightRound.svg";
import { ReactComponent as ArrowUp } from "../../svg/arrowUpRound.svg";

import { Store } from "../../interface";
import {
  DELETE_OBJECT,
  MOVE_OBJECT_DOWN,
  MOVE_OBJECT_LEFT,
  MOVE_OBJECT_RIGHT,
  MOVE_OBJECT_UP,
  SET_SIMULATION,
} from "../../store/types";
import { findParrentRung } from "../../helpers/simulationObjects";

import ActionButton from "./ActionButton";
import EditElementButton from "./EditElementButton";
import SimulateButton from "./SimulateButton";

const ActionList = styled.div`
  background: #e8d0f0;
  display: flex;
  flex: 1 1 auto;
  grid-area: actions;
`;

const Actions: React.FC = () => {
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const objectUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const rungs = useSelector((state: Store) => state.rungs);
  const elements = useSelector((state: Store) => state.elements);
  const runglist = useSelector((state: Store) => state.runglist);
  const dispatch = useDispatch();
  const handleClick = (actionType: string) => dispatch({ type: actionType });
  const isElement = Object.keys(elements).includes(objectUuid);
  const canMoveUp = runglist.indexOf(objectUuid) > 0;
  const canMoveDown = -1 < runglist.indexOf(objectUuid) && runglist.indexOf(objectUuid) < runglist.length - 1;
  const parrentRungUuid = findParrentRung(objectUuid, rungs);
  const rungElements = parrentRungUuid ? rungs[parrentRungUuid].elements : [];
  const canMoveLeft = isElement && rungElements.indexOf(objectUuid) > 0;
  const canMoveRight = isElement && rungElements.indexOf(objectUuid) < rungElements.length - 1;

  return (
    <ActionList
      data-intro="Move position of Logic elements in Ladder Diagram, assign variable to the element by editing its properties and start PLC Simulation!"
      data-step="3"
      onClick={() => simulation && dispatch({ type: SET_SIMULATION, payload: { value: false } })}
    >
      <ActionButton enabled={objectUuid !== ""} onClick={() => handleClick(DELETE_OBJECT)} Svg={Delete} />
      <ActionButton enabled={canMoveUp} onClick={() => handleClick(MOVE_OBJECT_UP)} Svg={ArrowUp} />
      <ActionButton enabled={canMoveDown} onClick={() => handleClick(MOVE_OBJECT_DOWN)} Svg={ArrowDown} />
      <ActionButton enabled={canMoveLeft} onClick={() => handleClick(MOVE_OBJECT_LEFT)} Svg={ArrowLeft} />
      <ActionButton enabled={canMoveRight} onClick={() => handleClick(MOVE_OBJECT_RIGHT)} Svg={ArrowRight} />
      <EditElementButton />
      <SimulateButton />
    </ActionList>
  );
};

export default Actions;
