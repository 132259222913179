import { makeStyles } from "@material-ui/core/styles";

export const signStyles = makeStyles((theme) => ({
  paper: {
    background: "white",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkbox: {
    marginLeft: "-11px",
    marginRight: "0.5rem",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    margin: theme.spacing(2, 0),
  },
}));
