import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Popup from "reactjs-popup";

import { ReactComponent as HelpSvg } from "../../svg/help.svg";

import SvgButton from "../SvgButton";

import TutorialVideo from "./TutorialVideo";

const StyledPopup = styled(Popup)`
  &-content {
    background: white;
    border: 0;
    max-width: 853px;
    padding: 0;
    width: 100%;
  }
`;

const pulse = keyframes`
25% {
  transform: scale(0.8);
}
75% {
  transform: scale(1.2);
}
`;

const StyledHelpSvg = styled(HelpSvg)`
  animation: ${pulse} 1s linear 5;
  animation-delay: 10s;
`;

const Help: React.FC = () => {
  const [videoOpen, setVideoOpen] = useState(false);
  return (
    <>
      <SvgButton onClick={() => setVideoOpen(true)} Svg={StyledHelpSvg} />
      <StyledPopup onClose={() => setVideoOpen(false)} open={videoOpen} modal={true} position="center center">
        <TutorialVideo />
      </StyledPopup>
    </>
  );
};

export default Help;
