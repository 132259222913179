import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ELEMENT, ELEMENT_NOT_CFG, OUT_ON } from "../../consts/colors";
import { ElementParameters, Store } from "../../interface";
import { OSN, OSP, XIC, XIO } from "../../consts/elementTypes";
import { SET_VAR_VALUE } from "../../store/types";

const Svg = styled.svg<{ fill: string }>`
  fill: ${(props) => props.fill};
  stroke: ${(props) => props.fill};
`;

interface Props {
  fill: string;
  parameters: ElementParameters;
  type: string;
}

const Contact: React.FC<Props> = (props: Props) => {
  const { fill, parameters, type } = props;
  const inputID = parameters.input[0].uuid;
  const variable = useSelector((state: Store) => state.variables[inputID]);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const dispatch = useDispatch();
  const contactFill = (() => {
    if (simulation) {
      switch (type) {
        case XIO:
          return !variable.value ? OUT_ON : ELEMENT;
        case XIC:
          return variable.value ? OUT_ON : ELEMENT;
        default:
          return fill;
      }
    } else return fill;
  })();

  const handleOnClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    simulation &&
      dispatch({
        type: SET_VAR_VALUE,
        payload: { uuid: inputID, value: !variable.value },
      });
  };

  return (
    <Svg onClick={(e) => handleOnClick(e)} fill={contactFill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <title>Contact</title>
      <text
        className="small"
        stroke={variable ? ELEMENT : ELEMENT_NOT_CFG}
        style={{ font: "14px sans-serif" }}
        x="8"
        y="12"
      >
        {variable ? variable.name : "--Select--"}
      </text>
      <line y1="40" x2="20" y2="40" strokeWidth="8" />
      <line x1="60" y1="40" x2="80" y2="40" strokeWidth="8" />
      <line x1="24" y1="16" x2="24" y2="64" strokeWidth="8" />
      <line x1="56" y1="16" x2="56" y2="64" strokeWidth="8" />
      {type === XIO && <line x1="52" y1="56" x2="28" y2="24" strokeWidth="8" />}
      <text x="30" y="50" style={{ font: "28px sans-serif" }}>
        {type === OSP && "P"}
        {type === OSN && "N"}
      </text>
    </Svg>
  );
};

export default Contact;
