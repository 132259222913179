import Gauge from './Gauge';
import Hmi from './Hmi';
import LightStack from './LightStack';
import StartStopButtons from './StartStopButtons';

export default function Cartoner() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x={40} y={0}>
      <g id="cartoner">
        <g id="machine">
          <rect x="68" y="355" width="236" height="88" style={{ fill: '#a1ebb0' }} />
          <rect x="68" y="332" width="236" height="23" style={{ fill: '#68df7f' }} />
          <rect x="50" y="30" width="272" height="302" rx="12" style={{ fill: '#c2d4ff' }} />
          <rect x="198" y="366" width="84" height="12" rx="6" style={{ fill: '#1c8731' }} />
          <rect x="198" y="390" width="84" height="12" rx="6" style={{ fill: '#1c8731' }} />
          <rect x="198" y="414" width="84" height="12" rx="6" style={{ fill: '#1c8731' }} />
          <Hmi />
          <StartStopButtons />
          <LightStack />
        </g>
        <g id="vent" transform="translate(70, -10)">
          <rect x="27" y="258" width="111" height="49" style={{ fill: '#fff' }} />
          <rect x="35" y="266" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="60" y="266" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="85" y="266" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="110" y="266" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="35" y="285" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="60" y="285" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="85" y="285" width="21" height="14" style={{ fill: '#002b8f' }} />
          <rect x="31" y="307" width="104" height="6" style={{ fill: '#002b8f' }} />
          <rect x="110" y="285" width="21" height="14" style={{ fill: '#002b8f' }} />
        </g>
        <Gauge x={90} y={-30} />
        <Gauge x={160} y={-30} />
      </g>
    </svg>
  );
}
