import { animated, config, useSpring } from '@react-spring/web';
import { useSelector } from 'react-redux';
import { Store } from '../interface';

interface Props {
  x: number;
  y: number;
}

export default function Gauge({ x, y }: Props) {
  const cartonerOn = useSelector(
    (state: Store) => state.variables['c37b7634-f531-4f44-85c7-308e16f9a81e'].value,
  );
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const styles = useSpring({
    to: { rotateZ: cartonerOn && simulation ? 60 : 0 },
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 476" width={48} x={x} y={y}>
      <g id="Face">
        <path
          d="M767,383.5q0,2.31,0,4.62H701c0-1.54,0-3.08,0-4.62a316.48,316.48,0,0,0-92.54-223.83L487.64,284.17c-.91-1-1.84-1.93-2.79-2.87L605.62,156.85A316.41,316.41,0,0,0,385.5,66V239l-2.5,0h-1.5V66C207.35,67.09,66,209.1,66,383.5c0,1.54,0,3.08,0,4.62H0q0-2.31,0-4.62C0,172.71,171,1.09,381.5,0h4A382.27,382.27,0,0,1,651.57,109.5l2.84,2.81C723.93,181.77,767,277.7,767,383.5Z"
          style={{ fill: '#4c4c4c' }}
        />
        <path
          d="M381.5,66V239c-78.71.8-142.5,65.09-142.5,144,0,1.71,0,3.42.1,5.12H66c0-1.54,0-3.08,0-4.62C66,209.1,207.35,67.09,381.5,66Z"
          style={{ fill: '#54df70' }}
        />
        <path
          d="M605.62,156.85,484.85,281.3A143.54,143.54,0,0,0,385.5,239V66A316.41,316.41,0,0,1,605.62,156.85Z"
          style={{ fill: '#f2c539' }}
        />
        <path
          d="M701,383.5c0,1.54,0,3.08,0,4.62H526.9c.07-1.7.1-3.41.1-5.12a143.49,143.49,0,0,0-39.36-98.83l120.82-124.5A316.48,316.48,0,0,1,701,383.5Z"
          style={{ fill: '#d63d28' }}
        />
      </g>
      <animated.g
        id="Indicator"
        style={{
          transform: 'rotate(-60deg)',
          transformOrigin: '50% 81%',
          ...styles,
          ...config.stiff,
        }}
      >
        <circle cx="383.5" cy="386.5" r="89.5" style={{ fill: '#e5e5e5' }} />
        <path
          d="M411.51,361.71a.05.05,0,0,0,0,0q-68.72-91-137.47-182s0,0,0,0a10,10,0,1,0-17.42,9.74l88,210.3a39.5,39.5,0,1,0,66.9-38Zm-29,40.79a14,14,0,1,1,14-14A14,14,0,0,1,382.5,402.5Z"
          style={{ fill: '#4c4c4c' }}
        />
      </animated.g>
    </svg>
  );
}
