import React, { useRef, useState } from 'react';
import { Avatar, Box, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import { signStyles } from './styles';
import { auth } from '../../helpers/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
interface Props {
  handleFormChange: (form: string) => void;
}

const PassRecover: React.FC<Props> = (props: Props) => {
  const { handleFormChange } = props;
  const classes = signStyles();
  const emailRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailRef.current) {
      sendPasswordResetEmail(auth, emailRef.current.value)
        .then(() => setSent(true))
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.paper}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)} className={classes.form} noValidate>
          <Grid item xs={12}>
            {error && (
              <Alert className={classes.alert} severity={'error'} color={'error'}>
                {error}
              </Alert>
            )}
            {sent && (
              <Alert className={classes.alert} severity={'success'} color={'success'}>
                Check your email for further instructions.
              </Alert>
            )}
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            inputRef={emailRef}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset password
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link onClick={() => handleFormChange('signIn')} variant="body2">
                Remember your password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={2}></Box>
    </Container>
  );
};

export default PassRecover;
