import { v4 as uuidv4 } from "uuid";

import { NEW_RUNG } from "../consts/ladderObjects";
import { Store } from "../interface";
import { findParrentRung } from "./simulationObjects";

export const addRung = (draft: Store): Store => {
  const newRungUuid = uuidv4();
  draft.rungs[newRungUuid] = NEW_RUNG;
  const { selectedUuid } = draft.temp;
  const parrentRungUuid = findParrentRung(selectedUuid, draft.rungs);
  const startIndex = draft.runglist.findIndex((rungUuid) => rungUuid === parrentRungUuid) + 1;
  draft.runglist.splice(startIndex, 0, newRungUuid);
  draft.temp.selectedUuid = newRungUuid;
  return draft;
};
