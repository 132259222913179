import Boxes from './Boxes';
import Conveyor from './Conveyor';
import Floor from './Floor';
import Cartoner from './Cartoner';
import Sensor from './Sensor';
import styled from 'styled-components';

const Container = styled.div`
  justify-content: center;
  background: linear-gradient(#ebedf7 80%, #dedfe7 80%);
  display: flex;
  grid-area: conveyor;
  position: relative;
`;

const StyledSvg = styled.svg`
  max-height: 400px;
`;

export default function Machine() {
  return (
    <Container>
      <StyledSvg viewBox="0 0 1600 480">
        <Boxes />
        <Floor />
        <Conveyor />
        <Cartoner />
        <Sensor uuid="76867e01-be5c-4c65-af69-934aff80e3a2" x={434} />
        <Sensor uuid="743e8009-2702-428d-a93c-cb05c77494c4" x={1408} />
      </StyledSvg>
    </Container>
  );
}
