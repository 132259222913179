import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'reactjs-popup/dist/index.css';
import './index.css';
import 'firebase/analytics';

import ErrorBoundary from './components/ErrorBoundary';
import Simulator from './components/Simulator';
import Loading from './components/Loading';
import { loadDiagram, persistor, store } from './store/store';

loadDiagram();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ErrorBoundary>
          <Simulator />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);

serviceWorkerRegistration.register();
