import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Store } from '../interface';

const rotate = keyframes`
  from {
    stroke-dashoffset: 100%;
  }

  to {
    stroke-dashoffset: 0%;
  }
`;

const RollerShadow = styled.circle<{ conveyorOn: boolean }>`
  display: inline-block;
  animation: ${rotate} 40s linear infinite;
  animation-play-state: ${(p) => (p.conveyorOn ? 'running' : 'paused')};
  padding: 2rem 1rem;
  font-size: 1.2rem;
`;

interface Props {
  position: number;
}

export default function Roller({ position }: Props) {
  const conveyorOn = useSelector(
    (state: Store) => state.variables['9df050ca-8888-4381-b866-813a60b59b18'].value,
  );
  const simulation = useSelector((state: Store) => state.temp.simulation);

  return (
    <g id="roller">
      <g>
        <circle r="14" cx={position} cy="161" fill="#b4d8ea" />
        <RollerShadow
          conveyorOn={conveyorOn && simulation ? true : false}
          r="7"
          cx={position}
          cy="161"
          fill="#b4d8ea"
          stroke="#97c0cd"
          strokeWidth="14"
          strokeDasharray={(6.283 * 7) / 4}
        />
      </g>
    </g>
  );
}
