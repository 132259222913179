import React from "react";
import styled from "styled-components";

const StyledIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Video = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`;
const TutorialVideo: React.FC = () => {
  return (
    <Video>
      <StyledIframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/Cgd_Or1Mcac?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Video>
  );
};
export default TutorialVideo;
