import React from "react";

import { EQU, NEQ, GRT, GEQ, LES, LEQ } from "../../consts/elementTypes";
import { ELEMENT, ELEMENT_NOT_CFG } from "../../consts/colors";

interface Props {
  configured: boolean;
  fill: string;
  type: string;
}

const Compare: React.FC<Props> = (props: Props) => {
  const { configured, fill, type } = props;
  const symbolColor = configured ? ELEMENT : ELEMENT_NOT_CFG;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className="symbol">
      <title>Compare</title>

      <rect x="16" y="16" width="48" height="48" fillOpacity="0" stroke={fill} strokeWidth="6" />
      <line y1="40" x2="14" y2="40" stroke={fill} strokeWidth="8" />
      <line x1="66" y1="40" x2="80" y2="40" stroke={fill} strokeWidth="8" />
      {type === EQU && (
        <svg>
          <line x1="24" x2="56" y1="34" y2="34" stroke={symbolColor} strokeWidth="6" />
          <line x1="24" x2="56" y1="46" y2="46" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === NEQ && (
        <svg>
          <line x1="24" x2="56" y1="34" y2="34" stroke={symbolColor} strokeWidth="6" />
          <line x1="24" x2="56" y1="46" y2="46" stroke={symbolColor} strokeWidth="6" />
          <line x1="32" x2="48" y1="56" y2="24" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === GRT && (
        <svg>
          <polyline points="28, 28 48, 40 28, 52" fill="none" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === GEQ && (
        <svg>
          <polyline points="28, 24 48, 36 28, 48" fill="none" stroke={symbolColor} strokeWidth="6" />
          <line x1="26" x2="54" y1="54" y2="54" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === LES && (
        <svg>
          <polyline points="52, 28 32, 40 52, 52" fill="none" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === LEQ && (
        <svg>
          <polyline points="52, 24 32, 36 52, 48" fill="none" stroke={symbolColor} strokeWidth="6" />
          <line x1="26" x2="54" y1="54" y2="54" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
    </svg>
  );
};

export default Compare;
