import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../interface';
import { SET_VAR_VALUE } from '../store/types';
interface Props {
  x: number;
  uuid: string;
}

export default function Sensor({ x, uuid }: Props) {
  const ref = useRef<SVGSVGElement>(null);
  const boxes = useSelector((state: Store) => state.temp.boxes);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const [state, setState] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    let boxOnSensor = false;
    boxes.forEach((box) => {
      if (!rect) return;
      if (box.position.left < rect?.left && box.position.right > rect?.left) boxOnSensor = true;
    });
    setState(boxOnSensor);
  }, [boxes]);

  useEffect(() => {
    if (simulation) {
      dispatch({
        type: SET_VAR_VALUE,
        payload: { uuid, value: state },
      });
    }
  }, [dispatch, simulation, state, uuid]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      x={x}
      y={34}
      width={21.27}
      viewBox="0 0 21.27 113.07"
    >
      <g id="Sensor">
        <rect x="2" width="16.87" height="41.37" style={{ fill: '#00adee' }} />
        <rect x="4" y="12.87" width="12.87" height="28.51" style={{ fill: '#016698' }} />
        <rect x="4" width="12.87" height="12.87" style={{ fill: '#404041' }} />
        <rect
          x="5.2"
          y="1.2"
          width="10"
          height="10"
          rx="4"
          ry="4"
          style={{ fill: state ? '#70c069' : '#417c3c' }}
        />
        <rect
          x="8.2"
          y="1.4"
          width="4"
          height="9.6"
          style={{ fill: state ? '#9cd092' : '#396d34' }}
        />
        <path
          d="M18.82,41.37v3.21c-1.98,.62-4.9,1.3-8.49,1.3-3.53,0-6.42-.68-8.38-1.3v-3.21H7.7v-2.67c.4-.24,1.4-.78,2.78-.76,1.27,.02,2.2,.51,2.6,.76v2.67h5.75Z"
          style={{ fill: '#333' }}
        />
        <path
          d="M10.33,44.63c.99,32.23-17.29,54.53-5.15,65.37,9.14,8.17,15.14-10.42,15.14-10.42"
          style={{ fill: 'none', stroke: '#333', strokeMiterlimit: 10, strokeWidth: '2px' }}
        />
      </g>
    </svg>
  );
}
