import React, { Fragment } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Store } from "../../interface";
import { OPEN_ELEM_PROPERTIES, SET_SIMULATION } from "../../store/types";

import { ReactComponent as EditPencil } from "../../svg/editPencil.svg";

import Properties from "../properties/Properties";
import ActionButton from "./ActionButton";

const PopupContainer = styled.div`
  padding: 1rem;
`;
const StyledPopup = styled(Popup)`
  &-content {
    background: white;
    border-radius: 1rem;
    width: 22rem !important;
  }
`;

const EditElementButton: React.FC = () => {
  const dispatch = useDispatch();
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const openElementProps = useSelector((state: Store) => state.temp.openElementProps);
  const elementKeys = useSelector((state: Store) => Object.keys(state.elements), shallowEqual);
  const enabled = elementKeys.includes(selectedUuid);
  const handleClick = () => {
    dispatch({ type: SET_SIMULATION, payload: { value: false } });
    if (enabled) {
      dispatch({ type: OPEN_ELEM_PROPERTIES, payload: true });
    }
  };

  return (
    <Fragment>
      <ActionButton enabled={enabled} onClick={() => handleClick()} Svg={EditPencil} />
      <StyledPopup
        onClose={() => dispatch({ type: OPEN_ELEM_PROPERTIES, payload: false })}
        open={openElementProps}
        modal={true}
        position="right center"
      >
        <PopupContainer>
          <Properties />
        </PopupContainer>
      </StyledPopup>
    </Fragment>
  );
};

export default EditElementButton;
