import React from "react";
import styled from "styled-components";
import { Button, Link, Typography } from "@material-ui/core";
import { SiDiscord, SiYoutube } from "react-icons/si";
import { styles } from "./styles";

const Container = styled.div`
  background-color: #abcdef;
  bottom: 0;
  display: flex;
  grid-area: footer;
  height: 1.75rem;
  justify-content: space-between;
  left: 0;
  position: relative;
  right: 0;
`;

const Left = styled.div`
  display: flex;
  margin: auto 0.5rem;
`;
const Right = styled.div`
  display: flex;
  margin: auto 0.5rem;
`;

const Footer: React.FC = () => {
  const classes = styles();
  return (
    <Container>
      <Left>
        <Button
          className={classes.footer}
          href="https://www.youtube.com/channel/UCugpLZ_CLaTRNs49tUylmfg"
          size="small"
          startIcon={<SiYoutube />}
        >
          YouTube
        </Button>
        <Button className={classes.footer} href="https://discord.gg/7XKvR2qvtM" size="small" startIcon={<SiDiscord />}>
          Discord
        </Button>
      </Left>
      <Right>
        <Typography className={classes.footer} variant="body2" align="center">
          {"© "}
          <Link className={classes.footer} href="https://www.codingplc.com/">
            CodingPLC
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
      </Right>
    </Container>
  );
};

export default Footer;
