import React from "react";
import styled from "styled-components";

const Line = styled.div<{ wireColor: string }>`
  background: linear-gradient(
    180deg,
    transparent calc(50% - 0.25em),
    ${(props) => props.wireColor} calc(50% - 0.25em),
    ${(props) => props.wireColor} calc(50% + 0.25em),
    transparent calc(50% + 0.25em)
  );
  height: 5em;
  width: 100%;
`;

interface Props {
  wireColor: string;
}

const Wire: React.FC<Props> = (props: Props) => {
  const { wireColor } = props;

  return <Line wireColor={wireColor} />;
};

export default Wire;
