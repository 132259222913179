import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../interface';
import { SET_VAR_VALUE } from '../store/types';

export default function StartStopButtons() {
  const pbStart = useSelector(
    (state: Store) => state.variables['9c8f00e2-e826-4c90-aa7c-299a351e32d6'].value,
  );
  const pbStop = useSelector(
    (state: Store) => state.variables['f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf'].value,
  );
  const dispatch = useDispatch();

  const setVar = (uuid: string, value: boolean) =>
    dispatch({
      type: SET_VAR_VALUE,
      payload: { uuid, value },
    });

  return (
    <g>
      <circle
        onMouseDown={() => setVar('9c8f00e2-e826-4c90-aa7c-299a351e32d6', true)}
        onMouseUp={() => setVar('9c8f00e2-e826-4c90-aa7c-299a351e32d6', false)}
        onMouseOut={() => setVar('9c8f00e2-e826-4c90-aa7c-299a351e32d6', false)}
        id="startButton"
        cx="270"
        cy="218"
        r="16"
        style={{
          fill: pbStart ? '#36AB4D' : '#47c560',
          stroke: '#4c4c4c',
          strokeMiterlimit: 10,
          strokeWidth: '6px',
        }}
      />
      <circle
        onMouseDown={() => setVar('f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf', true)}
        onMouseUp={() => setVar('f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf', false)}
        onMouseOut={() => setVar('f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf', false)}
        id="stopButton"
        cx="270"
        cy="280"
        r="16"
        style={{
          fill: pbStop ? '#BE3523' : '#DC5341',
          stroke: '#4c4c4c',
          strokeMiterlimit: 10,
          strokeWidth: '6px',
        }}
      />
    </g>
  );
}
