import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as Delete } from '../../svg/varDelete.svg';

import { DELETE_VARIABLE, SET_SIMULATION } from '../../store/types';
import { DELETE_COL_WIDTH } from '../../consts/variableTableStyles';
import { Store } from '../../interface';

const Container = styled.td`
  flex: 0 0 ${DELETE_COL_WIDTH};
  opacity: 0.8;
`;

interface Props {
  uuid: string;
}

const VariableDelete: React.FC<Props> = (props: Props) => {
  const { uuid } = props;
  const indelible = useSelector((state: Store) => state.variables[uuid].indelible);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({ type: DELETE_VARIABLE, payload: { uuid: uuid } });
    dispatch({ type: SET_SIMULATION, payload: { value: false } });
  };

  return (
    <Container>
      {indelible ? (
        <Delete height={'calc(1.4rem - 2px)'} opacity={0.3} />
      ) : (
        <Delete height={'calc(1.4rem - 2px)'} onClick={() => handleClick()} />
      )}
    </Container>
  );
};
export default VariableDelete;
