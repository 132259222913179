import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Store } from '../../interface';
import { BG_DIAGRAM } from '../../consts/colors';

import Rung from './Rung';

const Container = styled.div`
  background: ${BG_DIAGRAM};
  box-sizing: border-box;
  grid-area: diagram;
  height: 100%;
  padding: 0.25em;
  overflow: auto;
`;

const Diagram: React.FC = () => {
  const runglist = useSelector((state: Store) => state.runglist);
  return (
    <Container>
      {runglist.map((uuid: string) => (
        <Rung key={`rung${uuid}`} uuid={uuid} />
      ))}
    </Container>
  );
};

export default Diagram;
