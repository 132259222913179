import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Actions from './actions/Actions';
import Diagram from './diagram/Diagram';
import Footer from './Footer';
import Toolbox from './toolbox/Toolbox';
import Menu from './menu/Menu';
import VariableTable from './variables/VariableTable';
import AlertSnackbar from './AlertSnackbar';
import Machine from '../conveyor/Machine';
import MobileLandpage from './MobileLandpage';

const Desktop = styled.div`
  display: grid;
  grid-template: auto 3fr 1.2fr auto / 1fr 2fr;
  grid-template-areas:
    'header header'
    'variables diagram'
    'conveyor conveyor'
    'footer footer';
  height: 100%;
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-area: header;
  width: 100%;
`;
const Container = styled.div`
  font-size: 16px;
  height: 100%;
  width: 100%;
`;

const Simulator: React.FC = () => {
  const [mobileUI, setMobileUI] = useState(window.innerWidth < 1024);
  useEffect(() => {
    const handleResize = () => (window.innerWidth < 1024 ? setMobileUI(true) : setMobileUI(false));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Container>
      {mobileUI ? (
        <MobileLandpage />
      ) : (
        <Desktop>
          <Header>
            <Menu />
            <Toolbox />
            <Actions />
          </Header>
          <VariableTable />
          <Diagram />
          <Machine />
          <Footer />
        </Desktop>
      )}
      <AlertSnackbar />
    </Container>
  );
};

export default Simulator;
