import { Store } from '../interface';

export const INDELIBLE_VARS = {
  '9df050ca-8888-4381-b866-813a60b59b18': {
    name: 'conveyorOn',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  'c37b7634-f531-4f44-85c7-308e16f9a81e': {
    name: 'cartonerOn',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  'c73859d3-971c-4be0-8d68-3539213cf313': {
    name: 'lightstackAmber',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '93d762a0-dfed-4e08-aded-f573a741e316': {
    name: 'lightstackGreen',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  'baf9907b-dfbf-4483-a5a3-976f0e6ab00d': {
    name: 'lightstackRed',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '9c8f00e2-e826-4c90-aa7c-299a351e32d6': {
    name: 'pbStart',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  'f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf': {
    name: 'pbStop',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '76867e01-be5c-4c65-af69-934aff80e3a2': {
    name: 'sensor1',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '743e8009-2702-428d-a93c-cb05c77494c4': {
    name: 'sensor2',
    parrent: '',
    type: 'Bool',
    value: false,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '82f73a45-19db-4149-8a8a-72d079fc906b': {
    name: 'count1',
    parrent: '',
    type: 'Number',
    value: 0,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
  '940ba885-5c1c-4445-ae5c-46854e33a383': {
    name: 'count2',
    parrent: '',
    type: 'Number',
    value: 0,
    usedBy: [],
    subVars: {},
    indelible: true,
  },
};

export const SAMPLE_DRAFT: Store = {
  runglist: [
    '646b1d58-1615-4d9d-9002-66faa3d546a0',
    '709c9090-6bc0-4995-b0be-fce841954c5f',
    '636776ed-1d10-42d3-953a-27de3a0631a4',
    '819ea727-8664-4281-96b0-482fb1a51740',
    'c85652c7-c8c8-4153-be23-bfc022e8f211',
    'ae540004-181f-4dfa-861b-29d9adfde1f2',
    '78ff2d47-a632-4886-bde2-c32361b6e5e1',
    '083d1fd9-abb0-4b8e-8d1d-a5754dd960ca',
    '35238f5c-aef0-4bf9-9a01-681fa7a6ac17',
    'a351588c-6f15-4c32-bb86-fa02fd75a392',
  ],
  rungs: {
    '646b1d58-1615-4d9d-9002-66faa3d546a0': {
      elements: [
        '5c912051-70e9-453a-ad0c-a377c209d30e',
        '0e5d1afb-8ac1-48fb-8f3d-29b7f2858d39',
        'be2a16dd-d4fa-4fa0-bf6e-fd180aee0a61',
        '702aaa23-9bf2-4bb6-8ec9-1145e41ccfe0',
      ],
      comment: '',
      out: false,
      input: false,
    },
    'b215e553-79ed-46a0-b55d-9449ce8d702a': {
      comment: '',
      elements: ['8057370f-42c3-40f4-88ef-536359397a1c'],
      input: false,
      out: false,
    },
    'd03a4283-18cb-4cdb-bb68-8d7b8c7e9319': {
      comment: '',
      elements: ['c206812e-c74e-499a-8454-fc7c5a5552fa'],
      input: false,
      out: false,
    },
    'c85652c7-c8c8-4153-be23-bfc022e8f211': {
      comment: '',
      elements: ['90e5a6da-1842-4036-a452-8d7e4f81c264', '58e9058d-607b-442a-862f-9118d28c0d14'],
      input: false,
      out: false,
    },
    '78ff2d47-a632-4886-bde2-c32361b6e5e1': {
      comment: '',
      elements: [
        '867fe92e-7fe4-4214-ac41-59097acb7894',
        '2c3d65ca-3601-4309-bbaf-658c088d52fe',
        '0c725c2f-623a-4211-b32a-702545dcfbd3',
      ],
      input: false,
      out: false,
    },
    '083d1fd9-abb0-4b8e-8d1d-a5754dd960ca': {
      comment: '',
      elements: ['ebab197f-92b7-4836-a89f-1a42422a8da6', '60b65094-4283-42a6-8229-2b2bb118dd9a'],
      input: false,
      out: false,
    },
    '35238f5c-aef0-4bf9-9a01-681fa7a6ac17': {
      comment: '',
      elements: ['c98a71c4-9091-44cd-838a-5e48dd4d3d08', 'b80ac8d2-f65b-4e7d-a5fd-955fba26eecc'],
      input: false,
      out: true,
    },
    '819ea727-8664-4281-96b0-482fb1a51740': {
      comment: '',
      elements: [
        'daf9da57-83bd-4eb1-92e3-b114e2ef8e35',
        '7d92dc00-5adc-4eee-b3d3-7736e21bded5',
        '59344e3c-ff31-4d76-b6f9-319bd6bbfab6',
      ],
      input: false,
      out: false,
    },
    '709c9090-6bc0-4995-b0be-fce841954c5f': {
      comment: '',
      elements: ['d01c7b52-37a4-49f8-b229-8c73b6948744', 'bd8d3de6-c61c-471c-a7ad-d3a0dd6d2e5c'],
      input: false,
      out: false,
    },
    'ae540004-181f-4dfa-861b-29d9adfde1f2': {
      comment: '',
      elements: ['921fe313-e896-4e51-8009-dcf7a5c92122', '1a8c1451-c346-4441-9366-c699d669d282'],
      input: false,
      out: false,
    },
    '87f1f84d-89ab-48fa-abed-54c2dad86683': {
      comment: '',
      elements: ['04df3282-6cba-4c3b-bb40-3331c77886ea'],
      input: false,
      out: false,
    },
    '0a791911-2e10-4f0b-8f62-e7de37f98877': {
      comment: '',
      elements: ['959e0397-5a2b-47de-a5e3-5a3976c54fc0'],
      input: false,
      out: false,
    },
    '636776ed-1d10-42d3-953a-27de3a0631a4': {
      comment: '',
      elements: [
        '6c0bfff6-ed41-45d9-b260-ce1573f936d7',
        'dcf1599c-ad9f-47df-938b-274a0aba2521',
        '13e3afd4-1cb2-4af1-98fe-83abe427ee98',
        '6255d00a-706f-40d4-994f-a9f5795ba448',
      ],
      input: false,
      out: false,
    },
    'a351588c-6f15-4c32-bb86-fa02fd75a392': {
      comment: '',
      elements: ['cd824576-a298-4adb-ac6a-72f1282ea25d', 'fc40af42-a2ce-4f3c-9bac-5a44f8c13637'],
      input: false,
      out: false,
    },
  },
  branches: {
    '5c912051-70e9-453a-ad0c-a377c209d30e': {
      rungs: ['b215e553-79ed-46a0-b55d-9449ce8d702a', 'd03a4283-18cb-4cdb-bb68-8d7b8c7e9319'],
      input: false,
      out: false,
    },
    'be2a16dd-d4fa-4fa0-bf6e-fd180aee0a61': {
      rungs: ['87f1f84d-89ab-48fa-abed-54c2dad86683', '0a791911-2e10-4f0b-8f62-e7de37f98877'],
      input: false,
      out: false,
    },
  },
  elements: {
    '8057370f-42c3-40f4-88ef-536359397a1c': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9c8f00e2-e826-4c90-aa7c-299a351e32d6',
          },
        ],
      },
      type: 'XIC',
    },
    'c206812e-c74e-499a-8454-fc7c5a5552fa': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'XIC',
    },
    '0e5d1afb-8ac1-48fb-8f3d-29b7f2858d39': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf',
          },
        ],
      },
      type: 'XIO',
    },
    '702aaa23-9bf2-4bb6-8ec9-1145e41ccfe0': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'OTE',
    },
    '90e5a6da-1842-4036-a452-8d7e4f81c264': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '76867e01-be5c-4c65-af69-934aff80e3a2',
          },
        ],
      },
      type: 'XIC',
    },
    '58e9058d-607b-442a-862f-9118d28c0d14': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        inOut: [
          {
            type: ['Counter'],
            uuid: '09235b39-503c-4400-aa57-12aadfeafd63',
          },
        ],
      },
      type: 'CTU',
    },
    '2c3d65ca-3601-4309-bbaf-658c088d52fe': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'f1a1292c-3270-4f18-9840-20dbfeeb61ed',
          },
        ],
      },
      type: 'XIO',
    },
    '0c725c2f-623a-4211-b32a-702545dcfbd3': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: 'c37b7634-f531-4f44-85c7-308e16f9a81e',
          },
        ],
      },
      type: 'OTE',
    },
    'ebab197f-92b7-4836-a89f-1a42422a8da6': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '76867e01-be5c-4c65-af69-934aff80e3a2',
          },
        ],
      },
      type: 'XIC',
    },
    '60b65094-4283-42a6-8229-2b2bb118dd9a': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        inOut: [
          {
            type: ['Counter'],
            uuid: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
          },
        ],
      },
      type: 'CTU',
    },
    'c98a71c4-9091-44cd-838a-5e48dd4d3d08': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '743e8009-2702-428d-a93c-cb05c77494c4',
          },
        ],
      },
      type: 'OSN',
    },
    'b80ac8d2-f65b-4e7d-a5fd-955fba26eecc': {
      configured: true,
      memInput: false,
      out: true,
      parameters: {
        inOut: [
          {
            type: ['Counter'],
            uuid: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
          },
        ],
      },
      type: 'CTD',
    },
    'daf9da57-83bd-4eb1-92e3-b114e2ef8e35': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9df050ca-8888-4381-b866-813a60b59b18',
          },
        ],
      },
      type: 'XIO',
    },
    '7d92dc00-5adc-4eee-b3d3-7736e21bded5': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: 'd53104b7-5ca9-4370-9982-ed39ebd45a90',
          },
        ],
      },
      type: 'OTE',
    },
    '59344e3c-ff31-4d76-b6f9-319bd6bbfab6': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: '819b4199-2e6a-4d42-aee6-c14b15a6e780',
          },
        ],
      },
      type: 'OTE',
    },
    'd01c7b52-37a4-49f8-b229-8c73b6948744': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'XIC',
    },
    'bd8d3de6-c61c-471c-a7ad-d3a0dd6d2e5c': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: '93d762a0-dfed-4e08-aded-f573a741e316',
          },
        ],
      },
      type: 'OTE',
    },
    '921fe313-e896-4e51-8009-dcf7a5c92122': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'XIC',
    },
    '1a8c1451-c346-4441-9366-c699d669d282': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: '9df050ca-8888-4381-b866-813a60b59b18',
          },
        ],
      },
      type: 'OTE',
    },
    '04df3282-6cba-4c3b-bb40-3331c77886ea': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'f1a1292c-3270-4f18-9840-20dbfeeb61ed',
          },
        ],
      },
      type: 'XIO',
    },
    '959e0397-5a2b-47de-a5e3-5a3976c54fc0': {
      configured: true,
      memInput: true,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'dfad8091-22a8-4a0d-b169-70f6443badf5',
          },
        ],
      },
      type: 'XIO',
    },
    '6255d00a-706f-40d4-994f-a9f5795ba448': {
      configured: true,
      out: false,
      parameters: {
        output: [
          {
            type: ['Bool'],
            uuid: 'c73859d3-971c-4be0-8d68-3539213cf313',
          },
        ],
      },
      type: 'OTE',
    },
    'dcf1599c-ad9f-47df-938b-274a0aba2521': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'f1a1292c-3270-4f18-9840-20dbfeeb61ed',
          },
        ],
      },
      type: 'XIC',
    },
    '13e3afd4-1cb2-4af1-98fe-83abe427ee98': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: 'a5893d5b-7248-4d7f-9998-7a799a7701ee',
          },
        ],
      },
      type: 'XIO',
    },
    '6c0bfff6-ed41-45d9-b260-ce1573f936d7': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'XIC',
    },
    'cd824576-a298-4adb-ac6a-72f1282ea25d': {
      configured: true,
      out: false,
      parameters: {
        input: [
          {
            type: ['Number'],
            uuid: 'b6482810-4ee0-4a88-beea-23a4c6290752',
          },
        ],
        output: [
          {
            type: ['Number'],
            uuid: '82f73a45-19db-4149-8a8a-72d079fc906b',
          },
        ],
      },
      type: 'MOV',
    },
    'fc40af42-a2ce-4f3c-9bac-5a44f8c13637': {
      configured: true,
      out: false,
      parameters: {
        input: [
          {
            type: ['Number'],
            uuid: 'ca34d16f-848b-4805-9923-61fe0213f078',
          },
        ],
        output: [
          {
            type: ['Number'],
            uuid: '940ba885-5c1c-4445-ae5c-46854e33a383',
          },
        ],
      },
      type: 'MOV',
    },
    '867fe92e-7fe4-4214-ac41-59097acb7894': {
      configured: true,
      memInput: false,
      out: false,
      parameters: {
        input: [
          {
            type: ['Bool'],
            uuid: '9bdf20ea-0d2a-4029-8acf-2d46df2bc049',
          },
        ],
      },
      type: 'XIC',
    },
  },
  variables: {
    '9df050ca-8888-4381-b866-813a60b59b18': {
      name: 'conveyorOn',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    'c37b7634-f531-4f44-85c7-308e16f9a81e': {
      name: 'cartonerOn',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    'c73859d3-971c-4be0-8d68-3539213cf313': {
      name: 'lightstackAmber',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    '93d762a0-dfed-4e08-aded-f573a741e316': {
      name: 'lightstackGreen',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    'baf9907b-dfbf-4483-a5a3-976f0e6ab00d': {
      name: 'lightstackRed',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    '9c8f00e2-e826-4c90-aa7c-299a351e32d6': {
      name: 'pbStart',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    'f6d1af68-ded0-45cc-8ec6-fcc5f1652bdf': {
      name: 'pbStop',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    '76867e01-be5c-4c65-af69-934aff80e3a2': {
      name: 'sensor1',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    '743e8009-2702-428d-a93c-cb05c77494c4': {
      name: 'sensor2',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [],
      subVars: {},
      indelible: true,
    },
    '82f73a45-19db-4149-8a8a-72d079fc906b': {
      name: 'Total parts',
      parrent: '',
      type: 'Number',
      value: 0,
      usedBy: ['cd824576-a298-4adb-ac6a-72f1282ea25d'],
      subVars: {},
      indelible: true,
    },
    '940ba885-5c1c-4445-ae5c-46854e33a383': {
      name: 'Current parts',
      parrent: '',
      type: 'Number',
      value: 0,
      usedBy: ['fc40af42-a2ce-4f3c-9bac-5a44f8c13637'],
      subVars: {},
      indelible: true,
    },
    '09235b39-503c-4400-aa57-12aadfeafd63': {
      name: 'totalParts',
      parrent: '',
      type: 'Counter',
      value: null,
      usedBy: ['58e9058d-607b-442a-862f-9118d28c0d14'],
      subVars: {
        PV: '1a420c49-5de8-454f-9d90-eda10334eca5',
        CV: 'b6482810-4ee0-4a88-beea-23a4c6290752',
        CU: 'fc38f9c9-bd85-4830-9d24-6ca3528a3902',
        CD: '3c552fc6-de07-428f-ae33-ec0c49c4b9c8',
        R: 'd53104b7-5ca9-4370-9982-ed39ebd45a90',
        LD: '5acf0158-c34a-4cac-8edd-e391047e08ed',
        QU: 'f1a1292c-3270-4f18-9840-20dbfeeb61ed',
        QD: '610252c7-5c00-4668-8805-70a5e169f4b6',
      },
    },
    '1a420c49-5de8-454f-9d90-eda10334eca5': {
      name: 'totalParts.PV',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Number',
      usedBy: [],
      value: 15,
      subVars: {},
    },
    'b6482810-4ee0-4a88-beea-23a4c6290752': {
      name: 'totalParts.CV',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Number',
      usedBy: ['cd824576-a298-4adb-ac6a-72f1282ea25d'],
      value: 0,
      subVars: {},
    },
    'fc38f9c9-bd85-4830-9d24-6ca3528a3902': {
      name: 'totalParts.CU',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    '3c552fc6-de07-428f-ae33-ec0c49c4b9c8': {
      name: 'totalParts.CD',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    'd53104b7-5ca9-4370-9982-ed39ebd45a90': {
      name: 'totalParts.R',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: ['7d92dc00-5adc-4eee-b3d3-7736e21bded5'],
      value: false,
      subVars: {},
    },
    '5acf0158-c34a-4cac-8edd-e391047e08ed': {
      name: 'totalParts.LD',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    'f1a1292c-3270-4f18-9840-20dbfeeb61ed': {
      name: 'totalParts.QU',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: [
        '2c3d65ca-3601-4309-bbaf-658c088d52fe',
        '04df3282-6cba-4c3b-bb40-3331c77886ea',
        'dcf1599c-ad9f-47df-938b-274a0aba2521',
      ],
      value: false,
      subVars: {},
    },
    '610252c7-5c00-4668-8805-70a5e169f4b6': {
      name: 'totalParts.QD',
      parrent: '09235b39-503c-4400-aa57-12aadfeafd63',
      type: 'Bool',
      usedBy: [],
      value: true,
      subVars: {},
    },
    'f790d569-86bf-414b-84b2-1eaa813c7c54': {
      name: 'partsOnConv',
      parrent: '',
      type: 'Counter',
      value: null,
      usedBy: ['60b65094-4283-42a6-8229-2b2bb118dd9a', 'b80ac8d2-f65b-4e7d-a5fd-955fba26eecc'],
      subVars: {
        PV: '4b3204e0-0487-49c1-bfb1-ead96042a2df',
        CV: 'ca34d16f-848b-4805-9923-61fe0213f078',
        CU: '83516b36-01b9-410f-b3d2-5617b80e0616',
        CD: 'b055f390-aed2-42dc-833e-28b3dc8df959',
        R: '819b4199-2e6a-4d42-aee6-c14b15a6e780',
        LD: '4769d1f6-188a-48f8-a0e5-6b7608d303a9',
        QU: 'a5893d5b-7248-4d7f-9998-7a799a7701ee',
        QD: 'dfad8091-22a8-4a0d-b169-70f6443badf5',
      },
    },
    '4b3204e0-0487-49c1-bfb1-ead96042a2df': {
      name: 'partsOnConv.PV',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Number',
      usedBy: [],
      value: 10,
      subVars: {},
    },
    'ca34d16f-848b-4805-9923-61fe0213f078': {
      name: 'partsOnConv.CV',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Number',
      usedBy: ['fc40af42-a2ce-4f3c-9bac-5a44f8c13637'],
      value: 0,
      subVars: {},
    },
    '83516b36-01b9-410f-b3d2-5617b80e0616': {
      name: 'partsOnConv.CU',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    'b055f390-aed2-42dc-833e-28b3dc8df959': {
      name: 'partsOnConv.CD',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    '819b4199-2e6a-4d42-aee6-c14b15a6e780': {
      name: 'partsOnConv.R',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: ['59344e3c-ff31-4d76-b6f9-319bd6bbfab6'],
      value: false,
      subVars: {},
    },
    '4769d1f6-188a-48f8-a0e5-6b7608d303a9': {
      name: 'partsOnConv.LD',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: [],
      value: false,
      subVars: {},
    },
    'a5893d5b-7248-4d7f-9998-7a799a7701ee': {
      name: 'partsOnConv.QU',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: ['13e3afd4-1cb2-4af1-98fe-83abe427ee98'],
      value: false,
      subVars: {},
    },
    'dfad8091-22a8-4a0d-b169-70f6443badf5': {
      name: 'partsOnConv.QD',
      parrent: 'f790d569-86bf-414b-84b2-1eaa813c7c54',
      type: 'Bool',
      usedBy: ['959e0397-5a2b-47de-a5e3-5a3976c54fc0'],
      value: true,
      subVars: {},
    },
    '9bdf20ea-0d2a-4029-8acf-2d46df2bc049': {
      name: 'running',
      parrent: '',
      type: 'Bool',
      value: false,
      usedBy: [
        '702aaa23-9bf2-4bb6-8ec9-1145e41ccfe0',
        'c206812e-c74e-499a-8454-fc7c5a5552fa',
        'd01c7b52-37a4-49f8-b229-8c73b6948744',
        '921fe313-e896-4e51-8009-dcf7a5c92122',
        '6c0bfff6-ed41-45d9-b260-ce1573f936d7',
        '867fe92e-7fe4-4214-ac41-59097acb7894',
      ],
      subVars: {},
    },
  },
  temp: {
    alertSnackbar: {
      color: 'info',
      open: false,
      text: 'Initial diagram loaded.',
    },
    boxes: [],
    canUndo: false,
    canRedo: false,
    diagramSaved: false,
    openElementProps: false,
    simulation: false,
    selectedUuid: '867fe92e-7fe4-4214-ac41-59097acb7894',
  },
};

export const INITIAL_DRAFT: Store = {
  runglist: ['646b1d58-1615-4d9d-9002-66faa3d546a0'],
  rungs: {
    '646b1d58-1615-4d9d-9002-66faa3d546a0': {
      elements: [],
      comment: '',
      out: false,
      input: false,
    },
  },
  branches: {},
  elements: {},
  variables: {},
  temp: {
    alertSnackbar: {
      color: 'info',
      open: false,
      text: 'Initial diagram loaded.',
    },
    boxes: [],
    canUndo: false,
    canRedo: false,
    diagramSaved: false,
    openElementProps: false,
    simulation: false,
    selectedUuid: '',
  },
};

export const migrations = {
  1: () => {
    return { simulator: SAMPLE_DRAFT };
  },
  2: () => {
    return { simulator: SAMPLE_DRAFT };
  },
  3: () => {
    return { ...SAMPLE_DRAFT };
  },
};
