import { useSelector } from 'react-redux';
import { Store } from '../interface';

export default function Hmi() {
  const count1 = useSelector(
    (state: Store) => state.variables['82f73a45-19db-4149-8a8a-72d079fc906b'],
  );
  const count2 = useSelector(
    (state: Store) => state.variables['940ba885-5c1c-4445-ae5c-46854e33a383'],
  );

  return (
    <svg x="90" y="70">
      <rect
        x={6}
        y={6}
        width="180"
        height="85"
        rx="3.83"
        style={{ fill: '#eee', stroke: '#8991bd', strokeWidth: '12px' }}
      />
      <text font-weight="bold" fill="#555" x={30} y={38}>
        {count1.name}
      </text>
      <text font-weight="bold" fill="#555" x={140} y={38}>
        {count1.value}
      </text>
      <text font-weight="bold" fill="#555" x={30} y={72}>
        {count2.name}
      </text>
      <text font-weight="bold" fill="#555" x={140} y={72}>
        {count2.value}
      </text>
    </svg>
  );
}
