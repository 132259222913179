export const ADD_BRANCH: string = 'ADD_BRANCH';
export const ADD_ELEMENT: string = 'ADD_ELEMENT';
export const ADD_RUNG: string = 'ADD_RUNG';
export const ADD_VARIABLE: string = 'ADD_VARIABLE';

export const ASSIGN_PARAMETER: string = 'SELECT_PARAMETER';
export const CYCLE_SCAN: string = 'CYCLE_SCAN';

export const DELETE_OBJECT: string = 'DELETE_OBJECT';
export const DELETE_VARIABLE: string = 'DELETE_VARIABLE';
export const MOVE_OBJECT_DOWN: string = 'MOVE_OBJECT_DOWN';
export const MOVE_OBJECT_LEFT: string = 'MOVE_OBJECT_LEFT';
export const MOVE_OBJECT_RIGHT: string = 'MOVE_OBJECT_RIGHT';
export const MOVE_OBJECT_UP: string = 'MOVE_OBJECT_UP';

export const SELECT_OBJECT: string = 'SELECT_OBJECT';

export const SET_CONFIGURED: string = 'SET_CONFIGURED';
export const SET_SIMULATION: string = 'SET_SIMULATION';
export const SET_TYPE: string = 'SET_TYPE';
export const SET_VAR_NAME: string = 'SET_VAR_NAME';
export const SET_VAR_VALUE: string = 'SET_VAR_VALUE';

export const IMPORT_PROJECT: string = 'IMPORT_PROJECT';
export const LOAD_SAMPLE: string = 'LOAD_SAMPLE';
export const LOAD_EMPTY: string = 'LOAD_EMPTY';
export const REDO: string = 'REDO';
export const UNDO: string = 'UNDO';
export const SET_DIAG_SAVED: string = 'SET_DIAG_SAVED';
export const OPEN_ALERT_SNACKBAR: string = 'OPEN_ALERT_SNACKBAR';
export const CLOSE_ALERT_SNACKBAR: string = 'CLOSE_ALERT_SNACKBAR';

export const OPEN_ELEM_PROPERTIES: string = 'OPEN_ELEM_PROPERTIES';

export const ADD_BOX: string = 'ADD_BOX';
export const REMOVE_BOX: string = 'REMOVE_BOX';
export const UPDATE_BOX_POS: string = 'UPDATE_BOX_POS';
