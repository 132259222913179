import Roller from './Roller';

export default function Conveyor() {
  const circles = 16;
  const circlePositions = Array.from(Array(circles).keys(), (x) => (x * 1120) / (circles - 1) + 68);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x={340} y={116}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Conveyor">
          <rect
            x="6"
            y="131"
            width="1213"
            height="60"
            rx="30"
            style={{
              fill: '#def0f7',
              stroke: '#add9eb',
              strokeMiterlimit: 10,
              strokeWidth: '12px',
            }}
          />
          <g>
            <polygon
              points="12 0 41 15 41 184.85 1188.86 184.85 1188.86 200.64 12 200.64 12 0"
              style={{ fill: '#333' }}
            />
            <path
              d="M252.87,312.5H244.5v-112h-13v112h-8.37a24.93,24.93,0,0,0-10.7,16h51.14A24.93,24.93,0,0,0,252.87,312.5Z"
              style={{ fill: '#333' }}
            />
            <path
              d="M646.37,312H638V200H625V312h-8.37a24.93,24.93,0,0,0-10.7,16h51.14A24.93,24.93,0,0,0,646.37,312Z"
              style={{ fill: '#333' }}
            />
            <path
              d="M1040.37,312H1032V200h-13V312h-8.37a24.93,24.93,0,0,0-10.7,16h51.14A24.93,24.93,0,0,0,1040.37,312Z"
              style={{ fill: '#333' }}
            />
          </g>
          {circlePositions.map((position) => (
            <Roller key={`roller${position}`} position={position} />
          ))}
        </g>
      </g>
    </svg>
  );
}
