import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Store } from "../../interface";
import { ELEMENT, OUT_ON, SELECTED } from "../../consts/colors";

import Branch from "./Branch";
import Element from "./Element";
import Wire from "./Wire";

const BranchRail = styled.div<{ fill: string; top: boolean }>`
  background-color: ${(props) => props.fill};
  flex-shrink: 0;
  margin-top: ${(props) => (props.top ? 2.25 : 0)}em;
  height: ${(props) => (props.top ? "auto" : "2.75em")};
  width: 0.5em;
`;
const BranchRailTopRight = styled.div<{
  bottomFill: string;
  top: boolean;
  topFill: string;
}>`
  background: linear-gradient(
    180deg,
    ${(props) => props.topFill} 0,
    ${(props) => props.topFill} 0.5em,
    ${(props) => props.bottomFill} 0.5em,
    ${(props) => props.bottomFill} 100%
  );
  flex-shrink: 0;
  margin-top: ${(props) => (props.top ? 2.25 : 0)}em;
  height: ${(props) => (props.top ? "auto" : "2.75em")};
  width: 0.5em;
`;
const Container = styled.div`
  display: flex;
  min-height: 5em;
  min-width: 5em;
`;
const ElementList = styled.div`
  display: flex;
  width: 100%;
`;

interface Props {
  fillLeft: string;
  fillRight: string;
  fillMarginRight: string;
  uuid: string;
  parrentSelected: boolean;
  top: boolean;
}

const BranchRung: React.FC<Props> = (props: Props) => {
  const { uuid, fillLeft, fillRight, fillMarginRight, parrentSelected, top } = props;
  const dispatch = useDispatch();
  const rung = useSelector((state: Store) => state.rungs[uuid]);
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const diagramElements = useSelector((state: Store) => state.elements);
  const { elements, out } = rung;
  const selected = selectedUuid === uuid;
  const wireColor = simulation ? (out ? OUT_ON : ELEMENT) : selected || parrentSelected ? SELECTED : ELEMENT;

  const getComponentName = (elementUuid: string) => {
    if (Object.keys(diagramElements).includes(elementUuid)) return Element;
    else return Branch;
  };

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        return dispatch({
          type: "SELECT_OBJECT",
          payload: { uuid },
        });
      }}
    >
      <BranchRail fill={fillLeft} top={top} />
      <ElementList>
        {elements.map((uuid: string) => {
          const Component = getComponentName(uuid);
          return <Component key={`element${uuid}`} uuid={uuid} parrentSelected={selected || parrentSelected} />;
        })}
        <Wire wireColor={wireColor} />
      </ElementList>
      {top ? (
        <BranchRailTopRight bottomFill={fillRight} top={top} topFill={fillMarginRight} />
      ) : (
        <BranchRail fill={fillRight} top={top} />
      )}
    </Container>
  );
};

export default BranchRung;
