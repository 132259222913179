import React from "react";

import { ADD, DIV, MUL, SUB } from "../../consts/elementTypes";
import { ELEMENT, ELEMENT_NOT_CFG } from "../../consts/colors";

interface Props {
  configured: boolean;
  fill: string;
  type: string;
}

const Math: React.FC<Props> = (props: Props) => {
  const { configured, fill, type } = props;
  const symbolColor = configured ? ELEMENT : ELEMENT_NOT_CFG;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className="symbol">
      <title>Math</title>

      <rect x="16" y="16" width="48" height="48" fillOpacity="0" stroke={fill} strokeWidth="6" />
      <line y1="40" x2="14" y2="40" stroke={fill} strokeWidth="8" />
      <line x1="66" y1="40" x2="80" y2="40" stroke={fill} strokeWidth="8" />
      {type === ADD && (
        <svg>
          <line x1="24" x2="56" y1="40" y2="40" stroke={symbolColor} strokeWidth="6" />
          <line x1="40" x2="40" y1="24" y2="56" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === SUB && (
        <svg>
          <line x1="24" x2="56" y1="40" y2="40" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === MUL && (
        <svg>
          <line x1="26" x2="54" y1="26" y2="54" stroke={symbolColor} strokeWidth="6" />
          <line x1="54" x2="26" y1="26" y2="54" stroke={symbolColor} strokeWidth="6" />
        </svg>
      )}
      {type === DIV && (
        <svg>
          <line x1="24" x2="56" y1="40" y2="40" stroke={symbolColor} strokeWidth="6" />
          <circle cx="40" cy="30" r="4" fill={symbolColor} />
          <circle cx="40" cy="50" r="4" fill={symbolColor} />
        </svg>
      )}
    </svg>
  );
};

export default Math;
