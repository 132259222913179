export const BG_DIAGRAM = "#FFEBD6";
export const BG_ERROR = "#FFC2C2";
export const BG_MENU = "#FEF0AE";
export const BG_TOOLBOX = "#D3EEDE";
export const BG_VARIABLES = "#EBF5FF";
export const BG_WARNING = "#FEF0AE";
export const ELEMENT = "#000000";
export const ELEMENT_NOT_CFG = "#FF0000";
export const OUT_ON = "#18C97C";
export const PROGRESS = "#B7F5DA";
export const SELECTED = "#1973c8";
export const SUB_VAR_SELECTED = "#C2E1FF";
export const VAR_SELECTED = "#C2E1FF";
export const VAR_TABLE_BORDER = "#FFFFFF";
