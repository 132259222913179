import React from "react";

import { ELEMENT, ELEMENT_NOT_CFG } from "../../consts/colors";

interface Props {
  configured: boolean;
  fill: string;
}

const Math: React.FC<Props> = (props: Props) => {
  const { configured, fill } = props;
  const symbolColor = configured ? ELEMENT : ELEMENT_NOT_CFG;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className="symbol">
      <title>Move</title>

      <rect x="16" y="16" width="48" height="48" fillOpacity="0" stroke={fill} strokeWidth="6" />
      <line y1="40" x2="14" y2="40" stroke={fill} strokeWidth="8" />
      <line x1="66" y1="40" x2="80" y2="40" stroke={fill} strokeWidth="8" />
      <svg viewBox="0 0 80 80">
        <line x1="35" x2="56" y1="36" y2="36" stroke={symbolColor} strokeWidth="5" />
        <line x1="35" x2="56" y1="44" y2="44" stroke={symbolColor} strokeWidth="5" />
        <circle cx="28" cy="32" r="4" fill={symbolColor} />
        <circle cx="28" cy="48" r="4" fill={symbolColor} />
      </svg>
    </svg>
  );
};

export default Math;
