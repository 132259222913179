import { Box, Button, CssBaseline, Typography } from '@mui/material';
import { SiMailchimp } from 'react-icons/si';
import { SIMULATOR_LINK } from '../misc/const';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import typography from './typography';
import { ReactComponent as PlcSimulatorLogo } from '../svg/plc-simulator-logo.svg';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

export default function MobileLandpage() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#FFE01B',
      },
    },
    typography,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Box component="section" sx={{ p: 4 }}>
          <Box sx={{ p: 2 }}>
            <Typography gutterBottom component="h1" variant="h2" textAlign="center">
              Conveyor Simulator
            </Typography>
            <Typography align="center" component="p" gutterBottom variant="h3">
              <strong>
                Your screen resolution is too low to display Conveyor Simulator properly.
              </strong>
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography align="center" component="p" variant="h4">
              If you would like to use it on your mobile device please complete our survey.
            </Typography>
            <Box marginTop="1.5rem" textAlign="center">
              <Button
                color="secondary"
                size="large"
                href="https://us19.list-manage.com/survey?u=c96405bf9f5158c1ca2ff8e69&id=f9573ba75b&attribution=false"
                startIcon={<SiMailchimp />}
                target="_blank"
                variant="contained"
              >
                Complete survey
              </Button>
            </Box>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography align="center" component="p" gutterBottom variant="h4">
              You can also try our Ladder Logic simulator which is available on desktop and mobile
              devices.
            </Typography>
            <Box marginTop="1.5rem" textAlign="center">
              <Button
                color="primary"
                size="large"
                href={SIMULATOR_LINK}
                startIcon={<PlcSimulatorLogo />}
                target="_blank"
                variant="contained"
              >
                ladder simulator
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
