import React, { useState } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';

import { ReactComponent as SvgShare } from '../../svg/share.svg';

import PassRecover from './PassRecover';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SvgButton from '../SvgButton';

export const PopupContainer = styled.div`
  padding: 0.5rem;
`;
export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 1rem;
    width: 30rem !important;
  }
`;

const SignButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [signForm, setSignForm] = useState('signIn');

  const handleClose = () => {
    setOpen(false);
    setSignForm('signIn');
  };

  return (
    <>
      <SvgButton onClick={() => setOpen(true)} Svg={SvgShare} />
      <StyledPopup
        open={open}
        modal={true}
        position="right center"
        onClose={() => {
          handleClose();
        }}
      >
        {signForm === 'signIn' && <SignIn handleFormChange={(form) => setSignForm(form)} />}
        {signForm === 'signUp' && <SignUp handleFormChange={(form) => setSignForm(form)} />}
        {signForm === 'passRecover' && (
          <PassRecover handleFormChange={(form) => setSignForm(form)} />
        )}
      </StyledPopup>
    </>
  );
};

export default SignButton;
