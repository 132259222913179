import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../interface';
import { ADD_BOX } from '../store/types';
import Box from './Box';

export default function Boxes() {
  const boxes = useSelector((state: Store) => state.temp.boxes);
  const cartonerOn = useSelector(
    (state: Store) => state.variables['c37b7634-f531-4f44-85c7-308e16f9a81e'].value,
  );
  const conveyorOn = useSelector(
    (state: Store) => state.variables['9df050ca-8888-4381-b866-813a60b59b18'].value,
  );
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: ADD_BOX });
    }, 1200);
    if (!cartonerOn || !conveyorOn || !simulation) clearInterval(interval);
    return () => clearInterval(interval);
  }, [cartonerOn, conveyorOn, dispatch, simulation]);

  return (
    <>
      {boxes.map((box, index) => (
        <Box key={box.id} index={index} />
      ))}
    </>
  );
}
