import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ELEMENT, OUT_ON, SELECTED } from "../../consts/colors";
import { Store } from "../../interface";

import BranchRung from "./BranchRung";

const Container = styled.div`
  display: flex;
`;
const Margin = styled.div<{ fill: string }>`
  background-color: ${(props) => props.fill};
  flex-shrink: 0;
  margin-top: 2.25em;
  height: 0.5em;
  width: 0.5em;
`;
const RungList = styled.div`
  width: 100%;
`;

interface Props {
  uuid: string;
  parrentSelected: boolean;
}

const Branch: React.FC<Props> = (props: Props) => {
  const { uuid, parrentSelected } = props;
  const branches = useSelector((state: Store) => state.branches);
  const simulation = useSelector((state: Store) => state.temp.simulation);
  const selectedUuid = useSelector((state: Store) => state.temp.selectedUuid);
  const rungs = useSelector((state: Store) => state.rungs);
  const branch = branches[uuid];
  const branchRungs = branch.rungs;
  const [topRungUuid, bottomRungUuid] = branchRungs;
  const bottomRung = rungs[bottomRungUuid];
  const topRungSelected = selectedUuid === topRungUuid;
  const bottomRungSelected = selectedUuid === bottomRungUuid;

  const { input, out } = branch;
  const fillTopLeft = simulation ? (input ? OUT_ON : ELEMENT) : topRungSelected || parrentSelected ? SELECTED : ELEMENT;
  const fillTopRight = simulation
    ? bottomRung.out
      ? OUT_ON
      : ELEMENT
    : topRungSelected || parrentSelected
    ? SELECTED
    : ELEMENT;
  const fillBottomLeft = simulation
    ? input
      ? OUT_ON
      : ELEMENT
    : bottomRungSelected || parrentSelected
    ? SELECTED
    : ELEMENT;
  const fillBottomRight = simulation
    ? bottomRung.out
      ? OUT_ON
      : ELEMENT
    : bottomRungSelected || parrentSelected
    ? SELECTED
    : ELEMENT;
  const fillMarginRight = simulation
    ? out
      ? OUT_ON
      : ELEMENT
    : topRungSelected || parrentSelected
    ? SELECTED
    : ELEMENT;

  return (
    <Container>
      <Margin fill={fillTopLeft} />
      <RungList>
        <BranchRung
          uuid={topRungUuid}
          fillLeft={fillTopLeft}
          fillRight={fillTopRight}
          fillMarginRight={fillMarginRight}
          parrentSelected={parrentSelected}
          top={true}
        />
        <BranchRung
          uuid={bottomRungUuid}
          fillLeft={fillBottomLeft}
          fillRight={fillBottomRight}
          fillMarginRight={fillMarginRight}
          parrentSelected={parrentSelected}
          top={false}
        />
      </RungList>
      <Margin fill={fillMarginRight} />
    </Container>
  );
};

export default Branch;
